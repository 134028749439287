// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Sanitize = require("./sanitize").default;
var MarkdownIt = require("./markdownIt").default;

function markdownIt(prim) {
  return MarkdownIt(prim);
}

function sanitize(prim0, prim1) {
  return Sanitize(prim0, prim1);
}

function profileString(profile) {
  switch (profile) {
    case /* Comment */0 :
        return "comment";
    case /* QuestionAndAnswer */1 :
        return "questionAndAnswer";
    case /* Permissive */2 :
        return "permissive";
    case /* AreaOfText */3 :
        return "areaOfText";
    
  }
}

function parse(profile, markdown) {
  return Sanitize(profileString(profile), MarkdownIt(markdown));
}

exports.markdownIt = markdownIt;
exports.sanitize = sanitize;
exports.profileString = profileString;
exports.parse = parse;
/* ./sanitize Not a pure module */
