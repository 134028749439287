// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Json = require("../@glennsl/bs-json/lib/ocaml/Json.bs.js");
var $$Array = require("rescript/lib/js/array.js");
var React = require("react");
var ReactDom = require("react-dom");
var Json_decode = require("../@glennsl/bs-json/lib/ocaml/Json_decode.bs.js");
var MarkdownBlock = require("../shared/components/MarkdownBlock.bs.js");
var Caml_exceptions = require("rescript/lib/js/caml_exceptions.js");

var RootAttributeMissing = /* @__PURE__ */Caml_exceptions.create("ConvertMarkdownPack.RootAttributeMissing");

var InvalidProfile = /* @__PURE__ */Caml_exceptions.create("ConvertMarkdownPack.InvalidProfile");

function decodeProps(json) {
  return {
          markdown: Json_decode.field("markdown", Json_decode.string, json),
          profile: Json_decode.field("profile", Json_decode.string, json)
        };
}

function parseElement(element, attribute) {
  var props = element.getAttribute(attribute);
  var tmp;
  if (props == null) {
    throw {
          RE_EXN_ID: RootAttributeMissing,
          _1: attribute,
          Error: new Error()
        };
  }
  tmp = props;
  return decodeProps(Json.parseOrRaise(tmp));
}

function profileType(profile) {
  switch (profile) {
    case "areaOfText" :
        return /* AreaOfText */3;
    case "comment" :
        return /* Comment */0;
    case "permissive" :
        return /* Permissive */2;
    case "questionAndAnswer" :
        return /* QuestionAndAnswer */1;
    default:
      throw {
            RE_EXN_ID: InvalidProfile,
            _1: profile,
            Error: new Error()
          };
  }
}

function parseMarkdown(attributeNameOpt, attributeOpt, param) {
  var attributeName = attributeNameOpt !== undefined ? attributeNameOpt : "convert-markdown";
  var attribute = attributeOpt !== undefined ? attributeOpt : "data-json-props";
  return $$Array.map((function (element) {
                var props = parseElement(element, attribute);
                ReactDom.render(React.createElement(MarkdownBlock.make, {
                          markdown: props.markdown,
                          className: "leading-normal text-sm",
                          profile: profileType(props.profile)
                        }), element);
                
              }), Array.prototype.slice.call(document.getElementsByClassName(attributeName)));
}

parseMarkdown(undefined, undefined, undefined);

exports.RootAttributeMissing = RootAttributeMissing;
exports.InvalidProfile = InvalidProfile;
exports.decodeProps = decodeProps;
exports.parseElement = parseElement;
exports.profileType = profileType;
exports.parseMarkdown = parseMarkdown;
/*  Not a pure module */
